import { useState } from 'react';
import validator from 'validator';

import { useLogout } from './useLogout';

export const useEditProfile = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const { logout } = useLogout();

    const editPhone = async (authToken, curUserId, newPhoneNo) => {
        setIsLoading(true);
        setError(null);

        if (!validator.isMobilePhone(newPhoneNo)) {
            setError("Invalid Phone Number.");
            setIsLoading(false);
            return;
        } else {
            const response = await fetch('https://chabuy.store/api/user/' + curUserId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({phone: newPhoneNo})
            })
            const json = await response.json();
    
            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
            }
            if (response.ok) {
                setIsLoading(false);
                window.location.reload();
            }
        }
    }

    const editPassword = async (authToken, curUserId, newPW) => {
        setIsLoading(true);
        setError(null);

        if (newPW === '') {
            setError("Password cannot be empty");
            setIsLoading(false);
            return;
        } else {
            const response = await fetch('https://chabuy.store/api/user/update/password', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    userID: curUserId,
                    newPassword: newPW
                })
            })
            const json = await response.json();
    
            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
            }
            if (response.ok) {
                setIsLoading(false);
                logout();
                window.location.href= "/";
            }
        }
    }

    const editBankDetails = async (authToken, curUserId, newBank, newAccNumber, newAccName) => {
        setIsLoading(true);
        setError(null);

        if (newBank === '' || newAccNumber === '' || newAccName === '') {
            setError("Bank details cannot be empty!");
            setIsLoading(false);
            return;
        } else {
            const response = await fetch('https://chabuy.store/api/user/' + curUserId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    bankName: newBank,
                    accNumber: newAccNumber,
                    accName: newAccName
                })
            })
            const json = await response.json();
    
            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
            }
            if (response.ok) {
                setIsLoading(false);
                window.location.reload();
            }
        }
    }

    return { editPhone, editPassword, editBankDetails, isLoading, error };
}