import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useAuthContext } from '../hooks/useAuthContext';

const StarScore = () => {

  const { user } = useAuthContext();
  const [ordersData, setOrdersData] = useState([]);

  const fetchOrdersData = async () => {
    const response = await fetch('https://chabuy.store/api/order/user/' + user.username.toLowerCase(), {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    console.log(json);
    setOrdersData(json);
  }

  const [userData, setUserData] = useState();
  const getUserData = async () => {
    const response = await fetch('https://chabuy.store/api/user/'+ user.username.toLowerCase(), {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      if (json.length === 1) {
        // Expect only 1 result
        setUserData(json[0]);
        if (json[0].freezed) {
          localStorage.removeItem('chabevent_logged_in');
          window.location.href= "/";
        }
      } else {
        console.log("Invalid user data: More than 1 result.")
      }
    }
  }

  useEffect(() => {

    if (user) {
      getUserData();
      fetchOrdersData();
    }

	}, []);

  return (
    <>
      <div className="container form-page-padding">
        {/* Order History */}
        <div className="row mt-3 mb-5">
          <div className="col-12">
						<h3 className="mb-0">Star Point</h3>
					</div>
          <div className="col-12 px-2 mt-2">
            <div className="data-box d-flex justify-contain-left">
              <div className="data-icon-box bg-yellow">
                <span className="data-box-icon text-gray">
                  <i className="fa-solid fa-stars"></i>
                </span>
              </div>
              <div className="data-text-box">
                <h6 className="data-box-heading mb-0">Star Point</h6>
                <p className="data-box-number mb-0">{userData && userData.points} / 500</p>
              </div>
            </div>
          </div>
					<div className="col-12 mt-3">
            {ordersData && ordersData.length > 0 ? (
              <table className="table table-hover table-striped">
                <thead className="thead-dark">
                  <tr className="">
                    <th className="p-3" scope="col"><p className="mb-0">Order ID</p></th>
                    <th className="p-3" scope="col"><p className="mb-0">Status</p></th>
                    <th className="p-3" scope="col"><p className="mb-0">Date & Time</p></th>
                  </tr>
                </thead>
                <tbody>
                {ordersData && ordersData.map((orders, index) => (
                  <tr key={index}>
                    <td className="px-3 py-2">
                      <p className="mb-0">#{ordersData[index].orderID}</p>
                    </td>
                    <td className="px-3 py-2">
                      <p className="mb-0">
                        {ordersData[index].status === "Matching" ? <strong><span className="vip-yellow">{ordersData[index].status}</span></strong> : null}
                        {ordersData[index].status === "Matched" ? <strong><span className="profit-green">{ordersData[index].status}</span></strong> : null}
                        {ordersData[index].status === "Cancelled" ? <strong><span className="error">{ordersData[index].status}</span></strong> : null}
                      </p>
                    </td>
                    <td className="px-3 py-2">
                      <p className="mb-0">{Moment(ordersData[index].createdAt).format('YYYY-MM-DD HH:mm')}H</p>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            ):(
              <p className="">No order bidding history found.</p>
            )}
					</div>
        </div>
      </div>
    </>
  );

};

export default StarScore;